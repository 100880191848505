@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.animate-swing {
  animation: swing 3s ease-in-out infinite;
  transform-origin: bottom center;
}
